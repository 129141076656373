import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LinkTreePage from './components/LinkTree/LinkTreePage';
import NoMatch from './components/NoMatch';
import AdminPage from './pages/admin';
import HomePage from './pages/home';
import './App.css'

function App() {
  return (
    <Router>
      <div className="app">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/admin-for-multi" element={<AdminPage />} />
          <Route path="/:id" element={<LinkTreePage />} />
          <Route path="/404" element={<NoMatch />} />
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
