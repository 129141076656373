import React from 'react';
import './home.css'; // Assuming you have a Home.css for your styles

function Home() {
  // Inline styles can be used, but for maintainability and readability, it's often better to use a CSS file.
  return (
    <div className="home-background">
      <img src="/mascot_logo.png" className="mascot-logo" alt="Mascot" />
    </div>
  );
}

export default Home;