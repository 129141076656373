import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { formatDistanceToNow, parseISO } from 'date-fns';
import LinkForm from '../components/LinkTree/LinkForm';
import LinkItem from '../components/LinkTree/LinkItem';
import './admin.css'

function AdminPage() {
  const [links, setLinks] = useState([]);
  const [linkTreeCreated, setLinkTreeCreated] = useState(false);
  const [pages, setPages] = useState([]); // Array of link tree pages
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const pageSize = 200; // Number of pages per request

  const serverUrl = 'https://api.lemontee.co'; // Change this to your deployed server URL when necessary

  // Fetch pages when the component mounts or linkTreeCreated changes
  useEffect(() => {
    const fetchPages = async () => {
      try {
        const response = await axios.get(`${serverUrl}/pages/paginated?${currentPage}`);
        setPages(response.data);
        const countResponse = await axios.get(`${serverUrl}/pages/count`);
        setTotalPages(Math.ceil(countResponse.data.count / pageSize));
      } catch (error) {
        console.error('Error fetching pages:', error);
      }
    };

    fetchPages();
  }, [linkTreeCreated, currentPage]);

  const goToNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const goToPreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const addLink = (newLink) => {
    setLinks([...links, newLink]);
  };

  const deleteLink = async (linkIndex) => {
    // Assuming the backend is set up to delete a link by index
    const updatedLinks = [...links];
    updatedLinks.splice(linkIndex, 1);
    setLinks(updatedLinks);
    // Call backend API to update the links if needed
  };

  const createLinkTree = async () => {
    try {
      const response = await axios.post(`${serverUrl}/pages`, { links });
      setLinkTreeCreated(true);
      // Refetch pages to include the newly created one
      const pagesResponse = await axios.get(`${serverUrl}/pages`);
      response()
      setPages(pagesResponse.data);
      setLinks([])
    } catch (error) {
      console.error('Error creating link tree:', error);
    }
  };

  const deletePage = async (pageId) => {
    try {
      await axios.delete(`${serverUrl}/pages/${pageId}`);
      setPages(pages.filter(page => page._id !== pageId));
    } catch (error) {
      console.error('Error deleting page:', error);
    }
  };

  const formatRelativeTime = (timestamp) => {
    const date = parseISO(timestamp);
    return formatDistanceToNow(date, { addSuffix: true });
  };

  return (
    <div className='main-container'>
    <img src={"/mascot_logo.png"} alt="Mascot Logo" className="mascot-logo-title" />
      <LinkForm addLink={addLink} />
      <div className="link-list">
        {links.map((link, index) => (
          <LinkItem key={index} title={link.title} url={link.url} onDelete={() => deleteLink(index)} />
        ))}
      </div>
      {links.length > 0 && !linkTreeCreated && (
        <button className="create-link-tree-button" onClick={createLinkTree}>Make Link Tree</button>
      )}
      <h2>List of Link Trees:</h2>
        <div className="pagination">
          <button onClick={goToPreviousPage} disabled={currentPage === 1}>
            Previous
          </button>
          <span>Page {currentPage} of {totalPages}</span>
          <button onClick={goToNextPage} disabled={currentPage === totalPages}>
            Next
          </button>
        </div>
      <div className="link-tree-list">
        {pages.length > 0 ? (
          pages.map((page) => (
            <div key={page._id} className="page-item">
              <Link to={`/${page._id}`}>
                Page ID: {page._id}
              </Link>
              <span>Created {formatRelativeTime(page.createdAt)}</span>
              <button onClick={() => deletePage(page._id)}>Delete</button>
            </div>
          ))
        ) : (
          <p>No link trees yet.</p>
        )}
      </div>
    </div>
  );
}

export default AdminPage;
