// LinkTreePage.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import './LinkTreePage.css';

function LinkTreePage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [page, setPage] = useState(null);
  const server = "https://api.lemontee.co";

  useEffect(() => {
    const fetchPage = async () => {
      try {
        const response = await axios.get(`${server}/pages/${id}`);

        if (response.status === 404) {
          console.log('error 404');
          navigate('/404'); // Use the route you have for your NotFoundPage
        }

        setPage(response.data);
        console.log(response.data)
      } catch (error) {
        if (error.response && error.response.status === 404) {
          navigate('/404'); // Use the route you have for your NotFoundPage
        } else {
          console.error('Error fetching the page:', error);
        }
      }
    };

    fetchPage();
  }, [id, navigate]);

  if (!page) {
    return <div>Loading...</div>; // or some loading indicator
  }

  return (
    <div className="page-container">
      <img src={"/mascot_logo.png"} alt="Mascot Logo" className="mascot-logo-title" />
      <h1 className="page-title">ID: {page._id}</h1>
      <div className="links-container">
        {page.links.map((link, index) => (
          <div key={index} className="link-item">
            <a href={link.url} target="_blank" rel="noopener noreferrer">
              {link.title}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}

export default LinkTreePage;
